import CurrentUser from '@/store/models/CurrentUser';
import HttpClient from './ApiBase';
import LoginRequest from './models/requests/LoginRequest';
import ResetPassRequest from './models/requests/ResetPassRequest';
import VerifyEmailRequest from './models/requests/VerifyEmailRequest';
import VerifyPassRequest from './models/requests/VerifyPassRequest';

import LoginResponse from './models/responses/LoginResponse';
import LogoutResponse from './models/responses/LogoutResponse';

const BASE_PATH = '/partner';
const LOGIN_PATH = '/partner/login';
const USER_PATH = '/auth/user';
const LOGOUT_PATH = '/partner/logout';
const RESET_PATH = '/partner/reset-password';
const FORGOT_PATH = '/partner/forgot-password';
const CHANGE_PATH = '/partner/user';
const VERIFY_PATH = '/partner/setup-account';
const CHANGE_EMAIL_PATH = '/partner/email-change';

export const AttemptLogin = async (
  params: LoginRequest
): Promise<LoginResponse> => {
  const response = await HttpClient.post(LOGIN_PATH, { ...params });
  return response?.data;
};

export const GetUser = async (): Promise<CurrentUser> => {
  const response = await HttpClient.get(USER_PATH);
  return response?.data?.data as CurrentUser;
};

export const GetUserInformation = async (): Promise<CurrentUser> => {
  try {
    const response = await HttpClient.get<CurrentUser>('partner/user');
    return response.data;
  } catch {
    return {
      id: 0,
      name: '',
      email: '',
      orgRoles: [],
      orgs: [],
      phone: ''
    };
  }  
};

export const Logout = async (): Promise<LogoutResponse> => {
  const response = await HttpClient.post(LOGOUT_PATH);
  return response?.data;
};

export const ChangePassword = async (id: number, password: string) => {
  try {
    const response = await HttpClient.patch(`${CHANGE_PATH}/${id}`, {
      password: password
    });
    return response?.data;
  } catch {
    return -1;
  }
};

export const ForgotPassword = async (params: { email: string }) => {
  const response = await HttpClient.post(FORGOT_PATH, { ...params });
};

export const ResetPassword = async (
  params: ResetPassRequest
): Promise<LoginResponse> => {
  const response = await HttpClient.post(RESET_PATH, { ...params });
  return response?.data;
};

export const Verify = async (
  params: VerifyPassRequest
): Promise<LoginResponse> => {
  const response = await HttpClient.post(
    `${VERIFY_PATH}/${params.userId}?expires=${params.expires}&signature=${params.signature}`,
    { password: params.password }
  );
  return response?.data;
};

export const ChangeEmail = async (
  params: VerifyEmailRequest
): Promise<LoginResponse> => {
  const response = await HttpClient.post(
    `${CHANGE_EMAIL_PATH}/${params.userId}?expires=${params.expires}&signature=${params.signature}`
  );
  return response?.data;
};

export const ChangeUserDetails = async (id: number, params: {}) => {
  try {
    const response = await HttpClient.patch(`${CHANGE_PATH}/${id}`, params);
    return response?.data;
  } catch {
    return -1;
  }
};
