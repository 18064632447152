import User from '@/store/modules/User';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/home/Home.vue';
import { AdminGuard, ProductGuard } from './guards/product_guard';
import { PermissionName } from '@/store/models/PermissionName';
import { PermissionGuard } from './guards/permission-guard';
import { MultipleGuards } from './guards/multiple-guards';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/milk-view'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/milk-view',
    component: () =>
      import(
        /* webpackChunkName: "milk-view" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk' },
    beforeEnter: ProductGuard
  },
  {
    path: '/milk-route-plan',
    component: () =>
      import(
        /* webpackChunkName: "milk-route-plan" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk' },
    beforeEnter: ProductGuard
  },
  {
    path: '/milk-sites',
    component: () =>
      import(
        /* webpackChunkName: "milk-sites" */ '../views/sites-view/SitesView.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk' },
    beforeEnter: ProductGuard
  },
  {
    path: '/milk-sites/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "milk-sites/customer" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk' },
    beforeEnter: ProductGuard
  },
  {
    path: '/feed-view',
    component: () =>
      import(
        /* webpackChunkName: "feed-view" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'feed' },
    beforeEnter: ProductGuard
  },
  {
    path: '/water-view',
    component: () =>
      import(
        /* webpackChunkName: "water-view" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'water' },
    beforeEnter: ProductGuard
  },
  {
    path: '/generic-view',
    component: () =>
      import(
        /* webpackChunkName: "generic-view" */ '../views/product-view/GenericProductView.vue'
      ),
    meta: { requiresAuth: true, page: 'view' }
  },
  {
    path: '/feed-route-plan',
    component: () =>
      import(
        /* webpackChunkName: "feed-route-plan" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'feed' },
    beforeEnter: ProductGuard
  },
  {
    path: '/generic-route-plan',
    component: () =>
      import(
        /* webpackChunkName: "generic-route-plan" */ '../views/product-view/GenericProductView.vue'
      ),
    meta: { requiresAuth: true, page: 'route-plan' }
  },
  {
    path: '/fuel-view',
    component: () =>
      import(
        /* webpackChunkName: "fuel-view" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel' },
    beforeEnter: ProductGuard
  },
  {
    path: '/fuel-route-plan',
    component: () =>
      import(
        /* webpackChunkName: "fuel-route-plan" */ '../views/product-view/ProductView.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel' },
    beforeEnter: ProductGuard
  },
  {
    path: '/fuel-sites',
    component: () =>
      import(
        /* webpackChunkName: "fuel-sites" */ '../views/sites-view/SitesView.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel' },
    beforeEnter: ProductGuard
  },
  {
    path: '/water-sites',
    component: () =>
      import(
        /* webpackChunkName: "water-sites" */ '../views/sites-view/SitesView.vue'
      ),
    meta: { requiresAuth: true, productType: 'water' },
    beforeEnter: ProductGuard
  },
  {
    path: '/feed-sites',
    component: () =>
      import(
        /* webpackChunkName: "feed-sites" */ '../views/sites-view/SitesView.vue'
      ),
    meta: { requiresAuth: true, productType: 'feed' },
    beforeEnter: ProductGuard
  },
  {
    path: '/milk-requests',
    component: () =>
      import(
        /* webpackChunkName: "milk-sites" */ '../views/requests-view/RequestsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'milk',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/fuel-requests',
    component: () =>
      import(
        /* webpackChunkName: "fuel-sites" */ '../views/requests-view/RequestsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'fuel',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/water-requests',
    component: () =>
      import(
        /* webpackChunkName: "water-sites" */ '../views/requests-view/RequestsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'water',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/feed-requests',
    component: () =>
      import(
        /* webpackChunkName: "feed-sites" */ '../views/requests-view/RequestsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'feed',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/milk-requests/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "milk-requests" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'milk',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/fuel-requests/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "fuel-requests" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'fuel',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/water-requests/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "water-requests" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'water',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/feed-requests/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "feed-requests" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'feed',
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/milk-alerts',
    component: () =>
      import(
        /* webpackChunkName: "milk-alerts" */ '../views/alerts-view/AlertsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'milk',
      requiredPermission: PermissionName.viewAlerts
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/fuel-alerts',
    component: () =>
      import(
        /* webpackChunkName: "fuel-sites" */ '../views/alerts-view/AlertsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'fuel',
      requiredPermission: PermissionName.viewAlerts
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/water-alerts',
    component: () =>
      import(
        /* webpackChunkName: "water-sites" */ '../views/alerts-view/AlertsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'water',
      requiredPermission: PermissionName.viewAlerts
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/feed-alerts',
    component: () =>
      import(
        /* webpackChunkName: "feed-alerts" */ '../views/alerts-view/AlertsView.vue'
      ),
    meta: {
      requiresAuth: true,
      productType: 'feed',
      requiredPermission: PermissionName.viewAlerts
    },
    beforeEnter: MultipleGuards([PermissionGuard, ProductGuard])
  },
  {
    path: '/fuel-email-alerts',
    component: () =>
      import(
        /* webpackChunkName: "fuel-email-alerts" */ '../views/account/EmailAlerts.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel' },
    beforeEnter: ProductGuard
  },
  {
    path: '/milk-email-alerts',
    component: () =>
      import(
        /* webpackChunkName: "milk-email-alerts" */ '../views/account/EmailAlerts.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk' },
    beforeEnter: ProductGuard
  },
  {
    path: '/feed-email-alerts',
    component: () =>
      import(
        /* webpackChunkName: "feed-email-alerts" */ '../views/account/EmailAlerts.vue'
      ),
    meta: { requiresAuth: true, productType: 'feed' },
    beforeEnter: ProductGuard
  },
  {
    path: '/fuel-sites/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "fuel-sites/customer" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel' },
    beforeEnter: ProductGuard
  },
  {
    path: '/water-sites/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "water-sites/customer" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: { requiresAuth: true, productType: 'water' },
    beforeEnter: ProductGuard
  },
  {
    path: '/feed-sites/customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "feed-sites/customer" */ '../views/customer-view/CustomerView.vue'
      ),
    meta: { requiresAuth: true, productType: 'feed' },
    beforeEnter: ProductGuard
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/auth/LoginView.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */ '../views/auth/ChangePassword.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '../views/auth/ForgotPassword.vue'
      ),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/reset/:token/email/:email',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ '../views/auth/ResetPassword.vue'
      ),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/verify/:userId?',
    name: 'Verify',
    component: () =>
      import(/* webpackChunkName: "Verify" */ '../views/auth/Verify.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/auth/changeEmail/:userId?',
    name: 'ChangeEmail',
    component: () =>
      import(
        /* webpackChunkName: "ChangeEmail" */ '../views/auth/ChangeEmail.vue'
      ),
    meta: { requiresAuth: false }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/account/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/milk-settings/tab',
    name: 'milk.settings',
    component: () =>
      import(
        /* webpackChunkName: "MilkSettings" */ '../views/account/Settings.vue'
      ),
    meta: {
      // requiresAuth: true,
      // productType: 'milk',
      // tabGuard: true,
      // productGuard: true
    },
    children: [
      {
        path: 'user-management',
        name: 'milk.settings.user.management',
        meta: {
          tab: 'user-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editAndRemoveUsers,
            PermissionName.addUsers,
            PermissionName.setUserRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "MilkSettings" */ '../components/settings/UserManagement.vue'
          )
      },
      {
        path: 'role-management',
        name: 'milk.settings.role.management',
        meta: {
          tab: 'role-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editSystemRoles,
            PermissionName.createNewSystemRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "MilkSettings" */ '../components/settings/RoleManagement.vue'
          )
      },
      {
        path: 'alerts-settings',
        name: 'milk.settings.alerts.settings',
        meta: {
          tab: 'alerts-settings',
          requiresAuth: true,
          requiredPermission: PermissionName.editDefaultAlerts
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "MilkSettings" */ '../components/settings/AlertsSettingsTable.vue'
          )
      },
      {
        path: 'custom-field',
        name: 'milk.settings.custom.field',
        meta: {
          tab: 'custom-field',
          requiresAuth: true,
          requiredPermission: PermissionName.manageCustomFieldDefinitions
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "MilkSettings" */ '../components/settings/CustomFields.vue'
          )
      },
      {
        path: 'defaults',
        meta: {
          tab: 'defaults',
          requiresAuth: true,
          requiredPermission: PermissionName.editGeneralSettings
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "MilkSettings" */ '../components/settings/Defaults.vue'
          )
      },
      {
        path: '**',
        redirect: 'user-management'
      }
    ]
  },
  {
    path: '/fuel-settings/tab',
    name: 'fuel.settings',
    // beforeEnter: AdminGuard,
    component: () =>
      import(
        /* webpackChunkName: "FuelSettings" */ '../views/account/Settings.vue'
      ),
    meta: {
      // requiresAuth: true,
      // productType: 'fuel',
      // tabGuard: true,
      // productGuard: true
    },
    children: [
      {
        path: 'user-management',
        name: 'fuel.settings.user.management',
        meta: {
          tab: 'user-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editAndRemoveUsers,
            PermissionName.addUsers,
            PermissionName.setUserRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FuelSettings" */ '../components/settings/UserManagement.vue'
          )
      },
      {
        path: 'role-management',
        name: 'fuel.settings.role.management',
        meta: {
          tab: 'role-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editSystemRoles,
            PermissionName.createNewSystemRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FuelSettings" */ '../components/settings/RoleManagement.vue'
          )
      },
      {
        path: 'alerts-settings',
        name: 'fuel.settings.alerts.settings',
        meta: {
          tab: 'alerts-settings',
          requiresAuth: true,
          requiredPermission: PermissionName.editDefaultAlerts
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FuelSettings" */ '../components/settings/AlertsSettings.vue'
          )
      },
      {
        path: 'custom-field',
        name: 'fuel.settings.custom.field',
        meta: {
          tab: 'custom-field',
          requiresAuth: true,
          requiredPermission: PermissionName.manageCustomFieldDefinitions
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FuelSettings" */ '../components/settings/CustomFields.vue'
          )
      }
    ]
  },
  {
    path: '/feed-settings/tab',
    name: 'feed.settings',
    component: () =>
      import(
        /* webpackChunkName: "FeedSettings" */ '../views/account/Settings.vue'
      ),
    meta: {
      // requiresAuth: true,
      // productType: 'feed',
      // tabGuard: true,
      // productGuard: true
    },
    children: [
      {
        path: 'user-management',
        name: 'feed.settings.user.management',
        meta: {
          tab: 'user-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editAndRemoveUsers,
            PermissionName.addUsers,
            PermissionName.setUserRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FeedSettings" */ '../components/settings/UserManagement.vue'
          )
      },
      {
        path: 'role-management',
        name: 'feed.settings.role.management',
        meta: {
          tab: 'role-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editSystemRoles,
            PermissionName.createNewSystemRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FeedSettings" */ '../components/settings/RoleManagement.vue'
          )
      },
      {
        path: 'alerts-settings',
        name: 'feed.settings.alerts.settings',
        meta: {
          tab: 'alerts-settings',
          requiresAuth: true,
          requiredPermission: PermissionName.editDefaultAlerts
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FeedSettings" */ '../components/settings/AlertsSettings.vue'
          )
      },
      {
        path: 'custom-field',
        name: 'feed.settings.custom.field',
        meta: {
          tab: 'custom-field',
          requiresAuth: true,
          requiredPermission: PermissionName.manageCustomFieldDefinitions
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FeedSettings" */ '../components/settings/CustomFields.vue'
          )
      },
      {
        path: 'defaults',
        meta: {
          tab: 'defaults',
          requiresAuth: true,
          requiredPermission: PermissionName.editGeneralSettings
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "FeedSettings" */ '../components/settings/Defaults.vue'
          )
      },
      {
        path: '**',
        redirect: 'user-management'
      }
    ]
  },
  {
    path: '/water-settings/tab',
    name: 'water.settings',
    component: () =>
      import(
        /* webpackChunkName: "WaterSettings" */ '../views/account/Settings.vue'
      ),
    meta: {
      // requiresAuth: true,
      // productType: 'water',
      // tabGuard: true,
      // productGuard: true
    },
    children: [
      {
        path: 'user-management',
        name: 'water.settings.user.management',
        meta: {
          tab: 'user-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editAndRemoveUsers,
            PermissionName.addUsers,
            PermissionName.setUserRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "WaterSettings" */ '../components/settings/UserManagement.vue'
          )
      },
      {
        path: 'role-management',
        name: 'water.settings.role.management',
        meta: {
          tab: 'role-management',
          requiresAuth: true,
          requiresOnePermissionOf: [
            PermissionName.editSystemRoles,
            PermissionName.createNewSystemRoles
          ]
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "WaterSettings" */ '../components/settings/RoleManagement.vue'
          )
      },
      {
        path: 'alerts-settings',
        name: 'water.settings.alerts.settings',
        meta: {
          tab: 'alerts-settings',
          requiresAuth: true,
          requiredPermission: PermissionName.editDefaultAlerts
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "WaterSettings" */ '../components/settings/AlertsSettings.vue'
          )
      },
      {
        path: 'custom-field',
        name: 'water.settings.custom.field',
        meta: {
          tab: 'custom-field',
          requiresAuth: true,
          requiredPermission: PermissionName.manageCustomFieldDefinitions
        },
        beforeEnter: PermissionGuard,
        component: () =>
          import(
            /* webpackChunkName: "WaterSettings" */ '../components/settings/CustomFields.vue'
          )
      }
    ]
  },
  {
    path: '/milk-settings/add-field',
    name: 'MilkAddField',
    component: () =>
      import(
        /* webpackChunkName: "MilkSettings" */ '../views/form-view/AddFieldDataForm.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/fuel-settings/add-field',
    name: 'FuelAddField',
    component: () =>
      import(
        /* webpackChunkName: "FuelAddField" */ '../views/form-view/AddFieldDataForm.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/milk-help',
    name: 'MilkHelp',
    component: () =>
      import(/* webpackChunkName: "MilkHelp" */ '../views/Help.vue'),
    meta: { requiresAuth: true, productType: 'milk', pageName: 'help' },
    beforeEnter: ProductGuard
  },
  {
    path: '/fuel-help',
    name: 'FuelHelp',
    component: () =>
      import(/* webpackChunkName: "FuelHelp" */ '../views/Help.vue'),
    meta: { requiresAuth: true, productType: 'fuel', pageName: 'help' },
    beforeEnter: ProductGuard
  },
  {
    path: '/water-help',
    name: 'WaterHelp',
    component: () =>
      import(/* webpackChunkName: "FuelHelp" */ '../views/Help.vue'),
    meta: { requiresAuth: true, productType: 'water', pageName: 'help' },
    beforeEnter: ProductGuard
  },
  {
    path: '/feed-help',
    name: 'FeedHelp',
    component: () =>
      import(/* webpackChunkName: "FeedHelp" */ '../views/Help.vue'),
    meta: { requiresAuth: true, productType: 'feed', pageName: 'help' },
    beforeEnter: ProductGuard
  },
  {
    path: '/help',
    name: 'Help',
    component: () =>
      import(/* webpackChunkName: "FuelHelp" */ '../views/Help.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/pending-requests',
    component: () =>
      import(
        /* webpackChunkName: "pending-requests" */ '../views/form-view/PendingRequests.vue'
      ),
    meta: {
      requiresAuth: true,
      requiredPermission: PermissionName.viewRequests
    },
    beforeEnter: PermissionGuard
  },
  {
    path: '/milk-sites/install-request',
    component: () =>
      import(
        /* webpackChunkName: "install-request" */ '../views/form-view/AddMilkSite.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/milk-sites/edit-customer/:supplierNumber',
    component: () =>
      import(
        /* webpackChunkName: "edit-customer" */ '../views/form-view/EditMilkCustomer.vue'
      ),
    meta: { requiresAuth: true, productType: 'milk', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/fuel-sites/install-request',
    component: () =>
      import(
        /* webpackChunkName: "install-request" */ '../views/form-view/AddFuelSite.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/fuel-sites/edit-customer/:productId',
    component: () =>
      import(
        /* webpackChunkName: "edit-customer" */ '../views/form-view/EditFuelCustomer.vue'
      ),
    meta: { requiresAuth: true, productType: 'fuel', productGuard: true },
    beforeEnter: AdminGuard
  },
  {
    path: '/milk-settings/add-user',
    meta: { requiresAuth: true, requiredPermission: PermissionName.addUsers },
    component: () =>
      import(
        /* webpackChunkName: "install-request" */ '../views/form-view/AddUser.vue'
      ),
    beforeEnter: PermissionGuard
  },
  {
    path: '/fuel-settings/add-user',
    meta: { requiresAuth: true, requiredPermission: PermissionName.addUsers },
    component: () =>
      import(
        /* webpackChunkName: "fuel-site" */ '../views/form-view/AddUser.vue'
      ),
    beforeEnter: PermissionGuard
  },
  {
    path: '/water-settings/add-user',
    meta: { requiresAuth: true, requiredPermission: PermissionName.addUsers },
    component: () =>
      import(
        /* webpackChunkName: "water-settings" */ '../views/form-view/AddUser.vue'
      ),
    beforeEnter: PermissionGuard
  },
  {
    path: '/feed-settings/add-user',
    meta: { requiresAuth: true, requiredPermission: PermissionName.addUsers },
    component: () =>
      import(
        /* webpackChunkName: "feed-settings" */ '../views/form-view/AddUser.vue'
      ),
    beforeEnter: PermissionGuard
  },
  {
    path: '/removal',
    component: () =>
      import(
        /* webpackChunkName: "removal" */ '../views/form-view/Removal.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '**',
    redirect: '/milk-view'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  // Eventually will have to use to.matched for a number of routes.
  await User.tryRefreshUser();
  const navigatingTo = to.name?.toString();
  if (to.meta?.requiresAuth && User.isAuthenticated === false) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
